import { getManifest, setManifest } from '@angular-architects/module-federation';
import { TenantBootstrapConfig } from './app/auth/data-access/tenant-bootstrap-config.type';
import { CustomManifest } from './app/utils/config';
import { deepMergePlainObjects } from './app/utils/objects';
import { environment } from './environments/environment';
import { setupServiceWorker } from './service-worker/registration';

declare global {
  interface Window {
    bootstrapConfig: TenantBootstrapConfig;
  }
}

async function initializeBootstrap(): Promise<void> {
  await setupServiceWorker(getManifest());
  await import('./bootstrap');
}

async function fetchManifest(): Promise<void> {
  try {
    const response = await fetch(environment.tenantBootstrapConfigUrl);
    const data: TenantBootstrapConfig = (await response.json()) as TenantBootstrapConfig;
    if (environment.env === 'local') {
      data.shellManifest = (await fetch('assets/mf.manifest.local.json').then((res) => res.json())) as CustomManifest;
    }

    // Load local configuration for feature branch testing
    if (environment.env !== 'prod') {
      const localManifest = JSON.parse(localStorage.getItem('manifest') || '{}') as CustomManifest;
      data.shellManifest = deepMergePlainObjects(data.shellManifest, localManifest);
    }

    data.shellManifest = Object.entries(data.shellManifest)
      .sort(([, a], [, b]) => ((a.order ?? Infinity) > (b.order ?? Infinity) ? 1 : -1))
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

    window.bootstrapConfig = data;
    await setManifest(data.shellManifest, true);

    await initializeBootstrap();
  } catch (error) {
    console.error(error);
  }
}

await fetchManifest();
