export function deepMergePlainObjects<T>(a: T, b: T): T {
  const result = { ...a };
  for (const key in b) {
    if (Object.prototype.hasOwnProperty.call(b, key)) {
      result[key] = { ...result[key], ...b[key] };
    }
  }

  return result;
}
